import {HeatPointModel} from '../../models/entities/heat-point.model';
import {endOfDay, endOfYesterday, isAfter, startOfDay, startOfYesterday} from 'date-fns';
import {SavingsHeatChartRequestObj} from '../../models/entities/savings-heat-chart.model';


export const generateInitialDatesForForm = (): [Date, Date] => {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday = startOfDay(yesterday);
  let today = new Date();
  today = endOfDay(today);
  return [yesterday, today];
};


export const generateInitialDatesForFormTwoDaysAgo = (): [Date, Date] => {
  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  twoDaysAgo = startOfDay(twoDaysAgo);
  const yesterday = endOfYesterday();
  return [twoDaysAgo, yesterday];
};

export const correctFiltersObjectIfEndDateIsAfterToday =
  (savingsChartRequestObj: SavingsHeatChartRequestObj): SavingsHeatChartRequestObj => {

    const endDayInFuture = isAfter(
      startOfDay(savingsChartRequestObj.dateTo),
      startOfDay(new Date())
    );

    if (endDayInFuture) {
      savingsChartRequestObj.dateTo = endOfDay(new Date());
    }
    return savingsChartRequestObj;
  };


export const disabledDatesToday = (date: Date): boolean => {
  return isAfter(startOfDay(date), startOfDay(new Date()));
};

export const disabledDatesYesterday = (date: Date): boolean => {
  return isAfter(startOfDay(date), startOfYesterday());
};

export const genericCssClassesForTables = {
  filtersRowClassString: 'row mb-4',
  filtersColumnClassString: 'col-lg-5'
};


export interface GenericTableForm extends GenericTableFormWithoutHeatPoint {
  heatPoint: HeatPointModel;
}

export interface GenericTableFormWithoutHeatPoint {
  analysedPeriod: [Date, Date];
  comparedPeriod: [Date, Date];
}

export interface GenericTableRequestObjWithoutHeatPointId {
  analysedPeriod: Period;
  comparedPeriod: Period;
}

export interface GenericTableRequestObj extends GenericTableRequestObjWithoutHeatPointId {
  heatPointId: string;
}

export interface Period {
  dateFrom: Date;
  dateTo: Date;
}

export const getAnalysedAndComparedPeriodsFromFormWithHeatPointId = (
  {
    analysedPeriod,
    comparedPeriod,
    heatPoint
  }: GenericTableForm
): GenericTableRequestObj => (
  {
    heatPointId: heatPoint.id,
    analysedPeriod: {
      dateFrom: analysedPeriod[0],
      dateTo: analysedPeriod[1],
    },
    comparedPeriod: {
      dateFrom: comparedPeriod[0],
      dateTo: comparedPeriod[1],
    }
  }
);


export const getAnalysedAndComparedPeriodsFromForm = (
  {
    analysedPeriod,
    comparedPeriod,
    ...rest
  }: GenericTableFormWithoutHeatPoint
): GenericTableRequestObjWithoutHeatPointId => (
  {
    analysedPeriod: {
      dateFrom: analysedPeriod[0],
      dateTo: analysedPeriod[1],
    },
    comparedPeriod: {
      dateFrom: comparedPeriod[0],
      dateTo: comparedPeriod[1],
    },
    ...rest
  }
);


export const transformFiltersWithAnalysedAndComparedDatesToEdges = (filters: GenericTableRequestObjWithoutHeatPointId): void => {
  const {analysedPeriod, comparedPeriod} = filters;

  analysedPeriod.dateFrom = startOfDay(analysedPeriod.dateFrom);
  analysedPeriod.dateTo = endOfDay(analysedPeriod.dateTo);
  comparedPeriod.dateFrom = startOfDay(comparedPeriod.dateFrom);
  comparedPeriod.dateTo = endOfDay(comparedPeriod.dateTo);

};

export const transformFiltersWithDatesToEdges = (filters: FiltersWithStartAndEnd): void => {

  filters.dateFrom = startOfDay(filters.dateFrom);
  filters.dateTo = endOfDay(filters.dateTo);

};

export interface FiltersWithStartAndEnd {
  dateFrom: Date;
  dateTo: Date;
}
