import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerModel } from '../../models/entities/customer.model';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private configService: AppConfigService) {
  }

  getAll(): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(this.configService.API_URL.SA_CUSTOMERS);
  }

  get(id: string): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(this.configService.API_URL.SA_CUSTOMERS + '/' + id);
  }

  save(customer: Partial<CustomerModel>): Observable<CustomerModel> {
    return this.http.post<CustomerModel>(this.configService.API_URL.SA_CUSTOMERS, customer);
  }

  update({id, ...customer}: Partial<CustomerModel>): Observable<CustomerModel> {
    return this.http.put<CustomerModel>(this.configService.API_URL.SA_CUSTOMERS + '/' + id, customer);
  }

  remove(id: string): Observable<void> {
    return this.http.delete<void>(this.configService.API_URL.SA_CUSTOMERS + '/' + id);
  }
}
