import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class WordByWordTranslatorService {

  constructor(private translate: TranslateService,
  ) {
  }

  translateWordByWordAndJoin = (sentence: string): string => {
    let allWords: Array<string> = sentence.toString().split(' ');
    allWords = allWords.map(
      (word: string) => {
        return this.translate.instant(word.toUpperCase());
      }
    );

    return allWords.join(' ');
  };

}
