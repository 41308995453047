import {Injectable} from '@angular/core';
import {
  PredictedNetworkSupply,
  PredictedPowerModel,
  PredictedPowerNameEnum,
  PredictedPowerSampleModel
} from '../../../../models/entities/predicted-power.model';
import {HighChartSeriesPair, SeriesOptionsUnion} from '../balance-forecast-chart/balance-forecasts-chart.component';
import LoggerFactory from '../../../../shared/utils/logger';
import {AreaWithMappingsAndConfig} from '../../../../models/entities/area.model';
import {TranslateService} from '@ngx-translate/core';
import {getTime} from 'date-fns';

const logger = LoggerFactory.create('ChartSeriesProcessorService');


@Injectable({
  providedIn: 'root'
})
export class ChartSeriesProcessorService {
  private selectedHeatPointAreasWithMappings: AreaWithMappingsAndConfig[] = [];
  private rounding: number = 3;


  constructor(
    private translate: TranslateService,
  ) {
  }

  public prepareChartSeriesFromPredictedPowers = (
    predictedNetworkSupplies: PredictedNetworkSupply[],
    selectedHeatPointAreasWithMappings: AreaWithMappingsAndConfig[]
  ): Array<SeriesOptionsUnion> => {

    this.selectedHeatPointAreasWithMappings = selectedHeatPointAreasWithMappings;


    const highChartSeriesList: Array<SeriesOptionsUnion> = predictedNetworkSupplies
      .map(this.highChartSeriesMapper);

    logger.debug('highChartSeriesList prepared', highChartSeriesList);
    return highChartSeriesList;
  };


  private highChartSeriesMapper = (predictedPowerModel: PredictedPowerModel, index: number): SeriesOptionsUnion => {

    const areaWithMappings: AreaWithMappingsAndConfig | undefined = this.selectedHeatPointAreasWithMappings.find(
      (a: AreaWithMappingsAndConfig) => a.area.id === predictedPowerModel.areaId
    );
    const description: string = areaWithMappings
      ? areaWithMappings.area.description
      : '';
    const predictedPowerName: PredictedPowerNameEnum = predictedPowerModel.predictedPowerName;


    const name = this.computeName(predictedPowerName, description);

    const data: HighChartSeriesPair[] = this.computeData(predictedPowerModel);

    let chartSeriesType: 'line' | 'area' | 'column';
    let stack: number | undefined;

    switch (predictedPowerName) {
      case PredictedPowerNameEnum.AK_POWER:
      case PredictedPowerNameEnum.TEMPERATURES:
        chartSeriesType = 'line';
        break;
      case PredictedPowerNameEnum.HEAT_PUMP_POWER:
      case PredictedPowerNameEnum.OUTPUT_POWER:
      case PredictedPowerNameEnum.NETWORK_SUPPLY:
        chartSeriesType = 'area';
        break;
      default:
        chartSeriesType = 'column';
    }
    switch (predictedPowerName) {
      case PredictedPowerNameEnum.HEAT_PUMP_POWER:
      case PredictedPowerNameEnum.OUTPUT_POWER:
        stack = stackForOze;
        break;
      case PredictedPowerNameEnum.NETWORK_SUPPLY:
        stack = stackForNetworkSupply;
        break;
      default:
    }

    return {
      name,
      data,
      type: chartSeriesType,
      yAxis: predictedPowerName === PredictedPowerNameEnum.TEMPERATURES ? 1 : 0,
      color: predictedPowerName === PredictedPowerNameEnum.AK_POWER ? 'purple' : undefined,
      id: predictedPowerModel.areaId,
      zIndex: chartSeriesType === 'area'
        ? 1 + index
        : 100 + index,
      fillOpacity: chartSeriesType === 'area'
        ? 0.1
        : 0.9,
      stack,
      legendIndex: index
    };
  };

  private computeData = (predictedPowerModel: PredictedPowerModel): HighChartSeriesPair[] => {


    return predictedPowerModel.predictedPowerName !== PredictedPowerNameEnum.TEMPERATURES
      ? predictedPowerModel.entries.values
        .map(this.generateTimestampAndValueTuple)
      : predictedPowerModel.temperatures.values
        .map(this.generateTimestampAndValueTuple);
  };

  private computeName = (
    predictedPowerName: PredictedPowerNameEnum,
    description: string
  ): string => {
    let name: string;

    switch (predictedPowerName) {
      case PredictedPowerNameEnum.AK_POWER:
        name = this.translate.instant('AREA_REPORT.POWER_BALANCE_OF_CONTAINERS');
        break;
      case PredictedPowerNameEnum.OUTPUT_POWER:
      case PredictedPowerNameEnum.HEAT_PUMP_POWER:
        name = `${this.translate.instant('AREA_REPORT.PREDICTION_OF_POWER_GENERATED_BY')} ${this.translate.instant('AREA_REPORT.' + predictedPowerName)}`;
        break;
      case PredictedPowerNameEnum.NETWORK_SUPPLY:
        name = `${this.translate.instant('AREA_REPORT.NETWORK_SUPPLY')}`;
        break;
      case PredictedPowerNameEnum.TEMPERATURES:
        name = `${this.translate.instant('HEAT_POINTS.OUTSIDE_TEMPERATURE_SHORT')}`;
        break;
      default:
        name = `${this.translate.instant('AREA_REPORT.POWER_DEMAND')} ${description}`;
    }
    return name;
  };


  private generateTimestampAndValueTuple = (predictedPowerSampleModel: PredictedPowerSampleModel): HighChartSeriesPair => [
    this.parseExpectedDateFromSample(predictedPowerSampleModel.expectedDate),
    this.parseValueFromSample(predictedPowerSampleModel.value)
  ];


  private parseExpectedDateFromSample = (expectedDate: string): number => getTime(new Date(expectedDate));

  private parseValueFromSample = (valueFromSample: number) => (+(valueFromSample).toFixed(this.rounding));


}


const stackForOze = 1;
const stackForNetworkSupply = 2;
