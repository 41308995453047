import {HeatPointModel} from './heat-point.model';
import {AreaModel} from './area.model';
import {EntityModel} from '../entity.model';

export interface PredictedPowerModel extends IsForecastSeries, EntityModel {
  area?: AreaModel;
  areaId: string;
  entries: { values: PredictedPowerSampleModel[], unit: string, predictedPowerSum: { value: number, unit: string } } | null;
  heatPoint?: HeatPointModel;
  heatPointId: string;
  predictedPowerName: PredictedPowerNameEnum;
  temperatures: { values: PredictedPowerSampleModel[] } | null;
}

export interface IsForecastSeries {
  area?: AreaModel;
  entries: { values: PredictedPowerSampleModel[], unit: string, predictedPowerSum: { value: number, unit: string } } | null;
  heatPointId: string;
  predictedPowerName: PredictedPowerNameEnum;
}

export interface PredictedNetworkSupply extends IsForecastSeries {
  area?: AreaModel;
  areaId: string;
  entries: { values: PredictedPowerSampleModel[], unit: string, predictedPowerSum: { value: number, unit: string } } | null;
  heatPointId: string;
  predictedPowerName: PredictedPowerNameEnum;
}

export interface PredictedPowerSampleModel {
  expectedDate: string;
  value: number;
}


export enum PredictedPowerNameEnum {
  OUTPUT_POWER = 'OUTPUT_POWER',
  OUTPUT_ENERGY = 'OUTPUT_ENERGY',
  CO_POWER = 'CO_POWER',
  CWU_POWER = 'CWU_POWER',
  AK_POWER = 'AK_POWER',
  HEAT_PUMP_POWER = 'HEAT_PUMP_POWER',
  HEAT_PUMP_ENERGY = 'HEAT_PUMP_ENERGY',
  HEAT_PUMP_STATUS = 'HEAT_PUMP_STATUS',
  NETWORK_SUPPLY = 'NETWORK_SUPPLY',
  TEMPERATURES = 'TEMPERATURES'
}

