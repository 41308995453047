import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [{
  path: '',
  children: [
    {
      path: 'user-activity',
      loadChildren: './user-activity/user-activity.module#UserActivityModule',
      data: {
        breadcrumb: 'MENU.USERS_ACTIVITY'
      }
    },
    {
      path: 'area',
      loadChildren: './area/area-report.module#AreaReportModule',
      data: {
        breadcrumb: 'MENU.AREA_REPORT'
      }
    },
    {
      path: 'energy-balance',
      loadChildren: './energy-balance/energy-balance.module#EnergyBalanceModule',
      data: {
        breadcrumb: 'MENU.ENERGY_REPORT'
      }
    },
    {
      path: 'savings',
      loadChildren: './savings/savings.module#SavingsModule',
      data: {
        breadcrumb: 'MENU.SAVINGS'
      }
    },
    {
      path: 'heat-balance',
      loadChildren: './heat-balance/heat-balance.module#HeatBalanceModule',
      data: {
        breadcrumb: 'MENU.BALANCE'
      }
    },
    {
      path: 'alarms',
      loadChildren: './alarms/alarms-report.module#AlarmsReportModule',
      data: {
        breadcrumb: 'MENU.ALARMS'
      }
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'user-activity'
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
